import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ordersService } from "@/orders/service";
import { useAuth } from "@/AuthProvider";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { OrderResponseWithoutDimensions } from "@/orders/declaration";
import {
  Button,
  Form,
  InputNumber,
  Modal,
  Space,
  Table,
  Typography,
  Skeleton,
  Row,
  Col,
  notification,
  Input,
  InputRef,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { orderItemsService } from "@/orderItems/service";
import { OrderItemPayloadWithDimensions } from "@/orderItems/declaration";
import { CheckCircleTwoTone, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { FilterConfirmProps } from "antd/es/table/interface";

interface OrderActions {
  openModal: () => void;
}

interface DataType extends OrderResponseWithoutDimensions {
  fullName: string;
}

const params = new URLSearchParams({
  has_dimensions: false.toString(),
}).toString();
const key = "carpetCreationNotification";

const { Title } = Typography;

const OrdersActions = ({ openModal }: OrderActions) => {
  return (
    <div>
      <Button onClick={openModal} type="link">
        Καταχώρηση
      </Button>
    </div>
  );
};

const OrdersWithoutDimensions = () => {
  const { user } = useAuth();
  const [orderId, setOrderId] = useState<string | undefined>();
  const [customerName, setCustomerName] = useState<string>("");
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const openNotification = () => {
    api.open({
      key,
      message: "Καταχώρηση τετραγωνικών",
      description: "Τα τετραγωνικά καταχωρήθηκαν επιτυχώς.",
      placement: "bottomRight",
      icon: <CheckCircleTwoTone />,
    });
  };

  const { data: order, isFetching: isFetchingOrder } = useQuery({
    queryKey: ["orders", orderId],
    queryFn: () =>
      ordersService.find(orderId, {
        user,
        params,
      }),
    enabled: orderId != null,
  });

  const { isLoading: isUpdatingOrderItems, mutate: updateOrderItems } = useMutation(
    (body: OrderItemPayloadWithDimensions[]) => orderItemsService.updateMany(body, { user }),
    {
      onSuccess: () => {
        openNotification();
      },
    },
  );

  const { data: ordersWithoutDimensions, isLoading } = useQuery({
    queryKey: ["orders", params],
    queryFn: () =>
      ordersService.findAll({
        user,
        params,
      }),
  });

  useEffect(() => {
    /* Fixing issue when initial state of the form was not handled appropriately and
     * caused issues rendering form fields of order items from the previous order (aka stale data)
     */
    form.resetFields();
  }, [order]);

  function openModal({
    id,
    firstName,
    lastName,
  }: {
    id: string;
    firstName: string;
    lastName: string;
  }) {
    setOrderId(id);
    setCustomerName(`${lastName} ${firstName}`);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    form.resetFields();

    Promise.all([
      queryClient.invalidateQueries({ queryKey: ["orders", params] }),
      queryClient.invalidateQueries({ queryKey: ["orders", orderId] }),
    ]);
  }

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: string): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
      return (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`π.χ. Θεοδωρακόπουλος`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
            >
              Αναζήτηση
            </Button>
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters);
                confirm({ closeDropdown: false });
                close();
              }}
              size="small"
            >
              Εκκαθαρισμός
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record): boolean => {
      if (dataIndex === "fullName") {
        const searchValue = record.lastName + record.firstName;

        return searchValue
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
      }

      const rec = record[dataIndex as keyof DataType]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());

      return rec;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      );
    },
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Ονοματεπώνυμο",
      key: "firstName",
      render: ({ firstName, lastName }) => {
        return (
          <div>
            {lastName} {firstName}
          </div>
        );
      },
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName"),
    },
    {
      title: "Πόλη",
      key: "city",
      dataIndex: ["name"],
    },
    {
      title: "Ημερομηνία παραγγελίας",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (date) => {
        return (
          <div>
            {new Intl.DateTimeFormat("el-GR", {
              dateStyle: "long",
              timeZone: "Europe/Athens",
            }).format(new Date(date))}
          </div>
        );
      },
    },
    {
      title: "Διέυθυνση",
      key: "address",
      dataIndex: ["address"],
    },
    {
      title: "Eνέργειες",
      key: "actions",
      render: ({ id, firstName, lastName }) => (
        <OrdersActions openModal={() => openModal({ id, firstName, lastName })} />
      ),
    },
  ];

  // function handleSubmit(orderItems: OrderItemPayloadWithoutDimensions[]) {
  //   const composedOrderItems: OrderItemPayloadWithDimensions[] = orderItems.map((orderItem) => {
  //     return {
  //       id: orderItem.itemId,
  //       width: Number(orderItem.width),
  //       height: Number(orderItem.height),
  //       itemId: orderItem.itemId,
  //     };
  //   });

  //   updateOrderItems(composedOrderItems);
  // }
  //
  const orderItemsWithCustomerFullName: DataType[] | undefined = ordersWithoutDimensions?.map(
    (item) => {
      return {
        ...item,
        fullName: `${item.lastName} ${item.firstName}`,
      };
    },
  );

  console.log(orderItemsWithCustomerFullName);

  return (
    <>
      {contextHolder}
      <Table<DataType>
        style={{
          width: "100%",
        }}
        loading={isLoading}
        size={"small"}
        dataSource={orderItemsWithCustomerFullName}
        columns={columns}
        rowKey={"id"}
      />

      <Modal
        open={isOpen}
        title={`Καταχώρηση - ${customerName}`}
        onOk={form.submit}
        bodyStyle={{ height: "70vh", overflowX: "hidden", overflowY: "auto" }}
        width={"700px"}
        okButtonProps={{ disabled: isFetchingOrder, loading: isUpdatingOrderItems }}
        // This will fix issues in rendering dynamic fields
        forceRender
        okText="Καταχώρηση"
        cancelText="Ακύρωση"
        onCancel={closeModal}
      >
        <Form
          style={{ height: "100%" }}
          onFinish={() => {
            const orderItems = form.getFieldValue("orderItems");

            // handleSubmit(orderItems);

            updateOrderItems(orderItems);
          }}
          form={form}
          initialValues={{
            orderItems: order?.orderItems,
          }}
        >
          {isFetchingOrder ? (
            <Space direction="vertical">
              <Space direction="vertical" style={{ marginTop: "32px" }}>
                <Skeleton.Input active size="small" />
                <Space style={{ marginTop: "16px" }}>
                  <Skeleton.Input size="small" active />
                  <Skeleton.Input size="small" active />
                </Space>
              </Space>
              <Space direction="vertical" style={{ marginTop: "32px" }}>
                <Skeleton.Input active size="small" />
                <Space style={{ marginTop: "16px" }}>
                  <Skeleton.Input size="small" active />
                  <Skeleton.Input size="small" active />
                </Space>
              </Space>
            </Space>
          ) : null}
          <Form.List
            name="orderItems"
            // rules={[
            //   {
            //     validator: async (_, orderItems) => {
            //       // debugger;
            //       if (
            //         orderItems.every(
            //           (orderItem: any) => orderItem.width === null && orderItem.height === null,
            //         )
            //       ) {
            //         return Promise.reject(
            //           new Error("Δεν έχουν δοθεί διαστάσεις για κανένα από τα τεμάχια"),
            //         );
            //       }
            //     },
            //   },
            // ]}
          >
            {(fields, {}, { errors }) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {!isFetchingOrder &&
                      fields.map(({ key, name, ...restField }) => {
                        const title = form.getFieldValue(["orderItems", name, "item", "name"]);
                        return (
                          <React.Fragment key={key}>
                            <Form.Item>
                              <Title level={5}>{title}</Title>
                            </Form.Item>
                            <Row gutter={8} style={{ marginTop: "-16px" }}>
                              <Col span={8}>
                                <Form.Item
                                  {...restField}
                                  label="Μήκος"
                                  name={[name, "height"]}
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(a, value) {
                                        if (value <= 0 && value != null) {
                                          return Promise.reject(
                                            new Error(
                                              "Η διάσταση πρέπει να είναι μεγαλύτερη από 0",
                                            ),
                                          );
                                        }

                                        if (value >= 100) {
                                          return Promise.reject(
                                            new RangeError(
                                              "H διάσταση πρέπει να είναι μικρότερη από 100",
                                            ),
                                          );
                                        }

                                        const width = form.getFieldValue([
                                          "orderItems",
                                          name,
                                          "width",
                                        ]);

                                        if (value == null && width != null) {
                                          return Promise.reject(
                                            new Error("Δεν έχουν δοθεί και οι 2 διαστάσεις"),
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                      validateTrigger: "onSubmit",
                                    }),
                                  ]}
                                >
                                  <InputNumber />
                                </Form.Item>
                              </Col>
                              <Col>
                                <Form.Item
                                  {...restField}
                                  label="Πλάτος"
                                  name={[name, "width"]}
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(a, value) {
                                        if (value <= 0 && value != null) {
                                          return Promise.reject(
                                            new Error(
                                              "Η διάσταση πρέπει να είναι μεγαλύτερη από 0",
                                            ),
                                          );
                                        }

                                        if (value >= 100) {
                                          return Promise.reject(
                                            new RangeError(
                                              "H διάσταση πρέπει να είναι μικρότερη από 100",
                                            ),
                                          );
                                        }

                                        const height = form.getFieldValue([
                                          "orderItems",
                                          name,
                                          "height",
                                        ]);

                                        if (value == null && height != null) {
                                          return Promise.reject(
                                            new Error("Δεν έχουν δοθεί και οι 2 διαστάσεις"),
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                >
                                  <InputNumber />
                                </Form.Item>
                              </Col>
                            </Row>
                          </React.Fragment>
                        );
                      })}
                  </div>
                  <Form.ErrorList errors={errors} />
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export { OrdersWithoutDimensions };
