const sizeFormatter = new Intl.NumberFormat("el-GR", {
  style: "decimal",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
const euroFormatter = new Intl.NumberFormat("el-GR", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

function formatPrice(price: number) {
  return euroFormatter.format(price);
}

function formatSize(value: number) {
  return sizeFormatter.format(value);
}

export { formatPrice, formatSize };
