import { ApiError } from "@/utils/apiError";
import { User } from "firebase/auth";

const parseResponse = async <ResponseType>(resp: Response): Promise<ResponseType> => {
  if (resp.ok) {
    const res: ResponseType = await resp.json();

    return res;
  } else {
    const errorMessage: ApiError = await resp.json();

    return Promise.reject(new ApiError(errorMessage));
  }
};

interface RequestOptions extends RequestInit {
  body?: any;
  user: User | null;
}

const isDev = process.env.NODE_ENV === "development";

const authorizedRequest = async <ResponseType>(
  path: RequestInfo | URL,
  options: RequestOptions,
): Promise<ResponseType> => {
  const { headers, method = "GET", body, user, ...restOpts } = options;

  if (!user) {
    throw new ApiError({ message: "Unauthorized user", statusCode: 401 });
  }

  const authToken = await user!.getIdToken();

  let reqOptions: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      ...headers,
    },
    ...restOpts,
  };

  if (body) {
    reqOptions.body = typeof body === "object" ? JSON.stringify(body) : null;
  }

  const resp = await fetch(
    `${isDev ? "/api" : "https://carpets-backend-production.up.railway.app"}${path}`,
    reqOptions,
  );

  return parseResponse<ResponseType>(resp);
};

export { authorizedRequest };
