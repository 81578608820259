import * as React from "react";
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { carpetsService } from "@/carpets/service";
import { CarpetResponse } from "@/carpets/declaration";
import { customersService } from "@/customers/service";
import { useParams } from "react-router-dom";
import { CustomerResponse } from "@/customers/declaration";
import { useAuth } from "@/AuthProvider";
import { OrderRequest, OrderResponse } from "@/orders/declaration";
import { ordersService } from "@/orders/service";

interface NewOrderContextType {
  currentStep: number;
  prev: () => void;
  next: () => void;
  orderState: FormValues;
  setOrderState: React.Dispatch<React.SetStateAction<FormValues>>;
  carpets: CarpetResponse[] | undefined;
  isLoadingCarpets: boolean;
  carpetsError: unknown;
  fixedPriceMap: Map<string, string>;
  customer: CustomerResponse | undefined;
  isLoadingCustomer: boolean;
  customerError: unknown;
  createOrder: UseMutateFunction<OrderResponse, unknown, CreateOrderArgs, unknown>;
  isCreatingOrder: boolean;
  createOrderError: unknown;
  isCreateOrderSuccessful: boolean;
}

export interface FormValues {
  firstName?: string;
  lastName?: string;
  districtId?: string;
  cityId?: string;
  address?: string;
  orderItems: OrderItem[];
}

/* This is exclusively used as type to initial the form
 * so that it renders an item without width and height fields
 **/
export interface InitialPriceOrderItem {
  id: null;
  hasFixedPrice: true;
  name: "";
}

export interface FixedPriceOrderItem {
  id: string;
  hasFixedPrice: true;
  name: string;
}

export interface DynamicPriceOrderItem {
  id: string;
  width: number;
  height: number;
  name: string;
}

interface CreateOrderArgs {
  body: OrderRequest;
}

export type OrderItem = FixedPriceOrderItem | DynamicPriceOrderItem | InitialPriceOrderItem;

const NewOrderContext = React.createContext<NewOrderContextType | undefined>(undefined);

const initialOrderState = { orderItems: [{ id: null, name: "", hasFixedPrice: true } as const] };

const NewOrderProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient();
  const { customerId } = useParams();
  const { user } = useAuth();

  const [currentStep, setCurrentStep] = React.useState(0);
  const [orderState, setOrderState] = React.useState<FormValues>(initialOrderState);

  const {
    data: carpets,
    isLoading: isLoadingCarpets,
    error: carpetsError,
  } = useQuery({ queryKey: ["carpets"], queryFn: () => carpetsService.findAll({ user }) });

  const {
    data: customer,
    isLoading: isLoadingCustomer,
    error: customerError,
  } = useQuery({
    queryKey: ["customers", customerId],
    queryFn: () => customersService.find(customerId, { user }),
  });

  const {
    isLoading: isCreatingOrder,
    mutate: createOrder,
    error: createOrderError,
    isSuccess: isCreateOrderSuccessful,
  } = useMutation(({ body }: CreateOrderArgs) => ordersService.create(body, { user }), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
      queryClient.invalidateQueries({ queryKey: ["customers"] });
    },
  });

  const prev = React.useCallback(() => setCurrentStep((prev) => prev - 1), []);
  const next = React.useCallback(() => setCurrentStep((prev) => prev + 1), []);

  const fixedPriceMap = new Map<string, string>();

  if (carpets) {
    carpets.forEach((carpet) => {
      if (!carpet.hasFixedPrice) {
        return;
      }

      if (fixedPriceMap.has(carpet.id)) {
        return;
      }

      fixedPriceMap.set(carpet.id, carpet.name);
    });
  }

  const value: NewOrderContextType = {
    currentStep,
    prev,
    next,
    orderState,
    setOrderState,
    carpets,
    isLoadingCarpets,
    carpetsError,
    fixedPriceMap,
    customer,
    isLoadingCustomer,
    customerError,
    isCreatingOrder,
    createOrder,
    createOrderError,
    isCreateOrderSuccessful,
  };

  return <NewOrderContext.Provider value={value}>{children}</NewOrderContext.Provider>;
};

const useNewOrder = () => {
  const context = React.useContext(NewOrderContext);

  if (context === undefined) {
    throw new Error("useNewOrder must be used within a NewOrderProvider");
  }

  return context;
};

export { NewOrderProvider, useNewOrder };
