import { notification, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FunctionComponent } from "react";
import { useQuery } from "@tanstack/react-query";
import { customersService } from "@/customers/service";
import CustomersView from "./CustomersView";
import { CustomerResponse } from "../declaration";

import { DeleteTwoTone, MobileOutlined, PhoneOutlined } from "@ant-design/icons";

import "./styles.scss";
import { useAuth } from "@/AuthProvider";
import { Link } from "react-router-dom";

type TypeWithProperties = { id: string };

function composeTableData<DataType extends TypeWithProperties>(
  data: DataType[] | undefined,
): (DataType & TypeWithProperties)[] | undefined {
  if (!data) {
    return;
  }

  return data.map((datum) => ({
    ...datum,
    key: datum.id,
  }));
}

let CustomersTable: FunctionComponent<{}> = () => {
  const { user } = useAuth();

  const openNotification = () => {
    setTimeout(() => {
      api.open({
        message: "Διαγραφή πελάτη",
        description: "Ο πελάτης σας καθώς και οι παραγγελίες του έχουν διαγραφεί επιτυχώς.",
        placement: "bottomRight",
        icon: <DeleteTwoTone />,
      });
    }, 500);
  };

  const columns: ColumnsType<CustomerResponse> = [
    {
      title: "Ονοματεπώνυμο",
      key: "fullName",
      render: (customer) => {
        return (
          <Link to={`/customers/${customer.id}/orders`}>
            {customer.lastName} {customer.firstName}
          </Link>
        );
      },
    },
    {
      title: "Πόλη",
      key: "city",
      dataIndex: ["city", "name"],
    },
    {
      title: "Διέυθυνση",
      key: "address",
      dataIndex: "address",
    },
    {
      title: "Τηλέφωνα Επικοινωνίας",
      key: "phoneNumbers",
      dataIndex: "phoneNumbers",
      render: (phoneNumbers) => {
        const mobilePhoneRegex = /^69[0-9]{8}$/gi;

        let mobileNumbers = [];
        let landlineNumbers = [];
        for (let i = 0; i < phoneNumbers.length; i++) {
          if (phoneNumbers[i].match(mobilePhoneRegex)) {
            mobileNumbers.push(phoneNumbers[i]);
            continue;
          }

          landlineNumbers.push(phoneNumbers[i]);
        }

        return (
          <>
            {mobileNumbers.length ? (
              <div className="phone-numbers-container">
                <MobileOutlined className="phone-numbers-icon" />
                <div className="phone-numbers">
                  {mobileNumbers.map((mobileNumber: string, index) => {
                    return <div key={index}>{mobileNumber}</div>;
                  })}
                </div>
              </div>
            ) : null}

            {landlineNumbers.length ? (
              <div className="phone-numbers-container">
                <PhoneOutlined className="phone-numbers-icon" />
                <div className="phone-numbers">
                  {landlineNumbers.map((landlineNumbers: string, index) => {
                    return <div key={index}>{landlineNumbers}</div>;
                  })}
                </div>
              </div>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Eνέργειες",
      key: "actions",
      render: (customer) => (
        <CustomersView customer={customer} openNotification={openNotification} />
      ),
    },
  ];

  const [api, contextHolder] = notification.useNotification();
  const {
    data: customers,
    isLoading,
    error,
  } = useQuery<CustomerResponse[]>({
    queryKey: ["customers"],
    queryFn: () => customersService.findAll({ user }),
  });

  if (error) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      {contextHolder}
      <Table<CustomerResponse>
        style={{
          width: "100%",
        }}
        loading={isLoading}
        dataSource={composeTableData(customers)}
        columns={columns}
        size={"small"}
      />
    </>
  );
};

export default CustomersTable;
