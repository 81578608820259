import {
  Button,
  Space,
  Typography,
  Row,
  Col,
  Breadcrumb,
  Form,
  InputNumber,
  notification,
} from "antd";
import { OrderItemPayload, UpdateOrderItemPayload } from "@/orderItems/declaration";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ordersService } from "@/orders/service";
import { useAuth } from "@/AuthProvider";
import { OrderRequest, OrderResponse, UpdateOrderPayload } from "@/orders/declaration";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./style.scss";
import React from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";

const { Title, Text } = Typography;

const key = "order-update-notification";
interface FormValues {
  orderItems: OrderResponse["orderItems"];
  debt: number;
}

const EditOrder = () => {
  const [form] = Form.useForm();
  const { orderId, customerId } = useParams();
  const { user } = useAuth();
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();

  const openNotification = () => {
    api.open({
      key,
      message: "Τροποποίηση παραγγελίας",
      description: "H παραγγελία τροποποιήθηκε επιτυχώς.",
      placement: "bottomRight",
      icon: <CheckCircleTwoTone />,
    });
  };

  // function handleChange(orderItemId: string, itemIndex: number, carpets: CarpetResponse[]) {
  //   const { orderItems: previousOrderItems } = form.getFieldsValue(true);
  //
  //   const selectedCarpet = carpets.find((carpet) => carpet.id === orderItemId)!;
  //
  //   let newFormValues = [
  //     ...previousOrderItems.slice(0, itemIndex),
  //     {
  //       ...previousOrderItems[itemIndex],
  //       hasFixedPrice: selectedCarpet.hasFixedPrice,
  //       name: selectedCarpet.name,
  //     },
  //     ...previousOrderItems.slice(itemIndex + 1),
  //   ];
  //
  //   form.setFieldsValue({
  //     orderItems: newFormValues,
  //   });
  // }

  const {
    data: order,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["orders", orderId],
    queryFn: () => ordersService.find(orderId, { user }),
  });

  const {
    isLoading: isUpdatingOrder,
    mutate: updateOrder,
    error: updatedOrderError,
  } = useMutation((body: UpdateOrderPayload) => ordersService.update(orderId!, body, { user }), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders", orderId] });
      openNotification();
    },
  });

  function handleSubmit(values: FormValues) {
    const orderItems = values.orderItems.reduce<UpdateOrderPayload[]>(
      (newOrderItems, orderItem) => {
        const newOrderItem: UpdateOrderItemPayload = {
          id: orderItem.id,
          width: Number(orderItem.width),
          height: Number(orderItem.height),
        };

        return [newOrderItem, ...newOrderItems];
      },
      [],
    );

    const updateOrderPayload: UpdateOrderPayload = {
      id: orderId!,
      orderItems,
      debt: 0,
    };

    updateOrder(updateOrderPayload);
  }

  if (isLoading) {
    return <div>loading..</div>;
  }

  return (
    <div>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: "/customers",
            title: (
              <>
                <span>Πελάτες</span>
              </>
            ),
          },
          {
            title: (
              <>
                <span>Παραγγελίες πελάτη</span>
              </>
            ),
            href: `/customers/${customerId}`,
          },
          {
            href: `/customers/${customerId}/orders/${orderId}`,
            title: (
              <>
                <span>Παραγγελία</span>
              </>
            ),
          },
          {
            title: <span>Τροποποίηση παραγγελίας</span>,
          },
        ]}
      />
      <Title level={3}>{`${order!.customer.lastName} ${order!.customer.firstName}`}</Title>
      {/*<div*/}
      {/*  style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "80vw" }}*/}
      {/*>*/}
      <Form
        onFinish={handleSubmit}
        form={form}
        labelCol={{ span: 6 }}
        // wrapperCol={{
        //   xs: {
        //     span: 3,
        //   },
        //   lg: {
        //     span: 4,
        //   },
        //   xl: {
        //     span: 4,
        //   },
        //   xxl: {
        //     span: 4,
        //   },
        // }}
        requiredMark="optional"
        /*
         * Initial values will pre-add an empty order item
         * on initial page visit
         * */
        initialValues={{ orderItems: order!.orderItems }}
      >
        <Form.List name="orderItems" rules={[]}>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => {
                const hasFixedPrice = form.getFieldValue([
                  "orderItems",
                  field.name,
                  "item",
                  "hasFixedPrice",
                ]);
                const title = form.getFieldValue(["orderItems", field.name, "item", "name"]);
                return (
                  <React.Fragment key={field.key}>
                    <Row gutter={[4, 0]} align="middle">
                      <Col span={3}>
                        <Title level={5}>{title}</Title>
                        {/*{fields.length > 1 ? (*/}
                        {/*  <MinusCircleOutlined*/}
                        {/*    className="dynamic-delete-button"*/}
                        {/*    onClick={() => remove(field.name)}*/}
                        {/*  />*/}
                        {/*) : null}*/}
                      </Col>
                    </Row>

                    {/* This is to identify whether we shall provide
                      width and height fields to the user */}
                    {/*<Form.Item*/}
                    {/*  {...field}*/}
                    {/*  hidden*/}
                    {/*  key={`${field.key}-has-fixed-price`}*/}
                    {/*  name={[field.name, "hasFixedPrice"]}*/}
                    {/*>*/}
                    {/*  /!* This Input is prevent antd from raising errors for using*/}
                    {/*    Form.Item without using an input *!/*/}
                    {/*  <Input />*/}
                    {/*</Form.Item>*/}
                    {!hasFixedPrice && (
                      <Form.Item
                        key={`dimensions-${field.key}`}
                        required
                        wrapperCol={{
                          span: 8,
                        }}
                      >
                        {/*<Row gutter={[4, 0]} align="middle">*/}
                        {/*<Col span={4}>*/}
                        <Space align="start">
                          <Form.Item
                            {...field}
                            key={`${field.key}-height`}
                            name={[field.name, "height"]}
                            label={"Μήκος"}
                            required
                            className="dimension"
                            style={{ maxWidth: "170px" }}
                            rules={[
                              {
                                validator(a, value) {
                                  if (value <= 0 && value != null) {
                                    return Promise.reject(
                                      new Error("Η διάσταση πρέπει να είναι μεγαλύτερη από 0"),
                                    );
                                  }

                                  if (value >= 100) {
                                    return Promise.reject(
                                      new RangeError(
                                        "H διάσταση πρέπει να είναι μικρότερη από 100",
                                      ),
                                    );
                                  }

                                  const width = form.getFieldValue([
                                    "orderItems",
                                    field.name,
                                    "width",
                                  ]);

                                  if (value == null && width != null) {
                                    return Promise.reject(
                                      new Error("Δεν έχουν δοθεί και οι 2 διαστάσεις"),
                                    );
                                  }
                                  return Promise.resolve();
                                },
                                validateTrigger: "onSubmit",
                              },
                            ]}
                          >
                            <InputNumber />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            key={`${field.key}-width`}
                            name={[field.name, "width"]}
                            label="Πλάτος"
                            className="dimension"
                            style={{ maxWidth: "170px" }}
                            required
                            rules={[
                              {
                                validator(a, value) {
                                  if (value <= 0 && value != null) {
                                    return Promise.reject(
                                      new Error("Η διάσταση πρέπει να είναι μεγαλύτερη από 0"),
                                    );
                                  }

                                  if (value >= 100) {
                                    return Promise.reject(
                                      new RangeError(
                                        "H διάσταση πρέπει να είναι μικρότερη από 100",
                                      ),
                                    );
                                  }

                                  const height = form.getFieldValue([
                                    "orderItems",
                                    field.name,
                                    "height",
                                  ]);

                                  if (value == null && height != null) {
                                    return Promise.reject(
                                      new Error("Δεν έχουν δοθεί και οι 2 διαστάσεις"),
                                    );
                                  }
                                  return Promise.resolve();
                                },
                                validateTrigger: "onSubmit",
                              },
                            ]}
                          >
                            <InputNumber />
                          </Form.Item>
                        </Space>
                      </Form.Item>
                    )}
                  </React.Fragment>
                );
              })}
            </>
          )}
        </Form.List>
        <Button type="primary" htmlType="submit" loading={isUpdatingOrder}>
          Τροποποίηση παραγγελίας
        </Button>
      </Form>
    </div>
    // </div>
  );
};

export { EditOrder };
