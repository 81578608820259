import { RequestArgs, AuthorizationService } from "@/declaration";
import {
  OrderRequest,
  OrderResponse,
  OrderResponseWithoutDimensions,
  UpdateOrderPayload,
  UpdateOrderResponse,
} from "../declaration";
import { ApiError, authorizedRequest } from "@/utils";

class OrdersService
  implements
    AuthorizationService<
      OrderRequest,
      UpdateOrderPayload,
      OrderResponse | OrderResponseWithoutDimensions,
      UpdateOrderResponse
    >
{
  async create(body: OrderRequest, { user }: RequestArgs): Promise<OrderResponse> {
    const customer = authorizedRequest<OrderResponse>("/orders", {
      method: "POST",
      body,
      user,
    });

    return customer;
  }

  async delete(orderId: string): Promise<OrderResponse> {
    let res = await fetch(`/orders/${orderId}`);

    let order: OrderResponse = await res.json();

    return order;
  }

  async update(
    orderId: string,
    body: UpdateOrderPayload,
    args: RequestArgs,
  ): Promise<UpdateOrderResponse> {
    const order = await authorizedRequest<UpdateOrderResponse>(`/orders/${orderId}`, {
      method: "PATCH",
      body,
      user: args.user,
    });

    return order;
  }

  async find<ParamsType>(
    orderId: string | undefined,
    { user, params }: RequestArgs<ParamsType>,
  ): Promise<OrderResponse | undefined> {
    if (orderId === undefined) {
      return undefined;
    }

    const order = await authorizedRequest<OrderResponse>(
      `/orders/${orderId}${params ? `?${params}` : ""}`,
      {
        method: "GET",
        user,
      },
    );

    return order;
  }

  async findAll<ParamsType>({
    user,
    params,
  }: RequestArgs<ParamsType>): Promise<
    ParamsType extends string ? OrderResponseWithoutDimensions[] : OrderResponse[]
  > {
    const orders = await authorizedRequest<
      ParamsType extends string ? OrderResponseWithoutDimensions[] : OrderResponse[]
    >(`/orders${params ? `?${params}` : ""}`, {
      method: "GET",
      user,
    });

    return orders;
  }
}

const ordersService = new OrdersService();

export { ordersService };
