import { RequestArgs, AuthorizationService } from "@/declaration";
import { CarpetRequest, CarpetResponse } from "../declaration";
import { ApiError, authorizedRequest } from "@/utils";

class CarpetsService implements AuthorizationService<CarpetRequest, any, CarpetResponse, any> {
  async create(body: CarpetRequest, { user }: RequestArgs): Promise<CarpetResponse> {
    const carpet = authorizedRequest<CarpetResponse>("/carpets", {
      method: "POST",
      body,
      user,
    });

    return carpet;
  }

  async delete(carpetId: string): Promise<CarpetResponse> {
    let res = await fetch(`/carpets/${carpetId}`);

    let carpet: CarpetResponse = await res.json();

    return carpet;
  }

  async update(districtId: string, body: CarpetRequest): Promise<CarpetResponse> {
    throw Error("Not implemented");
  }

  async find(carpetId: string | undefined): Promise<CarpetResponse | undefined> {
    if (carpetId === undefined) {
      return;
    }

    let res = await fetch(`/carpets/${carpetId}`);

    if (!res.ok) {
      const resp = await res.json();
      throw new ApiError({ message: resp.message, statusCode: resp.statusCode });
    }

    let carpet: CarpetResponse = await res.json();

    return carpet;
  }

  async findAll({ user }: RequestArgs): Promise<CarpetResponse[]> {
    const carpets = await authorizedRequest<CarpetResponse[]>(`/carpets`, {
      method: "GET",
      user,
    });

    return carpets;
  }
}

let carpetsService = new CarpetsService();

export { carpetsService };
