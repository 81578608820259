import { Button, Result } from "antd";
import { Link } from "react-router-dom";

const ErrorPage: React.FunctionComponent = () => (
  <Result
    status="500"
    title="Κάτι πήγε στραβά"
    subTitle="Κάτι πήγε στραβά κατά την επεξεργασία δεδομένων. Παρακαλούμε, ειδοποιήστε το τεχνικό τμήμα για την επίλυση του προβλήματος."
    extra={
      <Button
        type="link"
        onClick={() => {
          window.location.href = "/";
        }}
      >
        Πίσω στην αρχική
      </Button>
    }
  />
);
export default ErrorPage;
