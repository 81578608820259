import { Button, Input, InputRef, Space, Table, Tag } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { FunctionComponent, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ordersService } from "@/orders/service";
import { OrderResponse } from "../declaration";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import "./styles.scss";
import OrdersActions from "./OrdersActions";
import { useAuth } from "@/AuthProvider";

type TypeWithProperties = { id: string; fullName: string };

type DataType = OrderResponse & { fullName: string };
type DataIndex = keyof DataType;

function composeTableData(
  data: OrderResponse[] | undefined,
): (DataType & TypeWithProperties)[] | undefined {
  if (!data) {
    return;
  }

  const res = data.map((datum) => ({
    ...datum,
    fullName: `${datum.customer.lastName}  ${datum.customer.firstName}`,
    key: datum.id,
  }));

  return res;
}

const OrdersTable: FunctionComponent<{}> = () => {
  const { user } = useAuth();
  const { data: orders, isLoading } = useQuery({
    queryKey: ["orders"],
    queryFn: () => ordersService.findAll({ user }),
  });
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`π.χ. Θεοδωρακόπουλος`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Αναζήτηση
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({ closeDropdown: false });
              close();
            }}
            size="small"
          >
            Εκκαθαρισμός
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record): boolean => {
      if (dataIndex === "fullName") {
        const searchValue = record.customer.lastName + record.customer.firstName;

        return searchValue
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
      }

      return record[dataIndex]!.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      );
    },
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Ονοματεπώνυμο",
      key: "fullName",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName"),
    },
    {
      title: "Πόλη",
      key: "city",
      dataIndex: ["customer", "city", "name"],
    },
    {
      title: "Ημερομηνία παραγγελίας",
      key: "orderDate",
      dataIndex: "orderDate",
      render: (date) => {
        return (
          <div>
            {new Intl.DateTimeFormat("el-GR", {
              dateStyle: "long",
              timeZone: "Europe/Athens",
            }).format(new Date(date))}
          </div>
        );
      },
    },
    {
      title: "Διέυθυνση",
      key: "address",
      dataIndex: ["customer", "address"],
    },
    // {
    //   title: "Κατάσταση παραγγελίας",
    //   key: "state",
    //   render: (order: OrderResponse) => {
    //     if (order.debt === 0) {
    //       return <Tag color="success">Πληρωμένή</Tag>;
    //     }
    //
    //     if (order.debt === order.summary) {
    //       return <Tag color="warning">Μερικώς πληρωμένη</Tag>;
    //     }
    //
    //     return <Tag color="error">Απλήρωτη</Tag>;
    //   },
    // },
    {
      title: "Eνέργειες",
      key: "actions",
      render: OrdersActions,
    },
  ];

  return (
    <Table<DataType>
      style={{
        width: "100%",
      }}
      loading={isLoading}
      size={"small"}
      dataSource={composeTableData(orders)}
      columns={columns}
    />
  );
};

export default OrdersTable;
