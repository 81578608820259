import { NewOrderProvider, useNewOrder } from "@/orders/NewOrder/NewOrderContext";
import { Button, Space, Typography, Row, Col, Divider, Breadcrumb, Skeleton } from "antd";
import { CarpetResponse } from "@/carpets/declaration";
import { FixedOrderItem } from "@/orders/NewOrder/newOrderSteps/Step2/FixedOrderItem";
import { formatPrice, formatSize } from "@/orders/utils";
import { grey, red } from "@ant-design/colors";
import { DynamicOrderItemResponse, OrderItemResponse } from "@/orderItems/declaration";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ordersService } from "@/orders/service";
import { useAuth } from "@/AuthProvider";
import { OrderItemOfOrderPayloadWithDimensions, OrderRequest } from "@/orders/declaration";
import { Link, useParams, useNavigate } from "react-router-dom";
import React from "react";
import { isDynamicOrderItem, isFixedOrderItem } from "@/orderItems/utils";

const { Title, Text } = Typography;

const VAT = 0.24;

export interface FixedPriceOrderItemsGroup {
  name: string;
  amount: number;
  hasFixedPrice: true;
}

type GroupedFixedPriceOrderItemsTypesById = Record<string, FixedPriceOrderItemsGroup>;

interface GetOrderItemsWithPricesArgs {
  dynamicPriceOrderItems: DynamicOrderItemResponse[];
  groupedFixedPriceOrderItemsTypesById: GroupedFixedPriceOrderItemsTypesById;
  carpets: CarpetResponse[];
}

function calculateNetPrice(orderItems: OrderItemResponse[]) {
  const hasRemainingPrices = orderItems.some((orderItem) => !orderItem.price);

  if (hasRemainingPrices) {
    return 0;
  }

  const result = orderItems.reduce((acc, item) => acc + Number(item.price)!, 0);

  return result;
}

// TODO (low importance) fix navigation with customer id
// in case the customer id in the url is wrong, the user still gets the correct data

const DynamicOrderItem = (props: DynamicOrderItemResponse) => {
  console.log(props.width);
  return (
    <>
      <Col span={1}>1</Col>
      <Col span={4}>{props.item.name}</Col>
      <Col span={4}>
        {props.width ? (
          <Row style={{ maxWidth: "100px" }}>
            <Col span={8} style={{ textAlign: "right" }}>
              {formatSize(props.width)}
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              *
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              {props.height && formatSize(props.height)}
            </Col>
          </Row>
        ) : (
          <em style={{ color: red[4] }}>Δεν έχουν δοθεί διαστάσεις</em>
        )}
      </Col>
      <Col style={{ textAlign: "right" }} span={15}>
        {props.price ? formatPrice(props.price) : ""}
      </Col>
    </>
  );
};

const OrderView1 = () => {
  const { orderId, customerId } = useParams();
  const { user } = useAuth();
  const { data: order, isLoading } = useQuery({
    queryFn: () => ordersService.find(orderId, { user }),
    queryKey: ["orders", orderId],
  });

  const navigate = useNavigate();
  const { carpets, fixedPriceMap, next, prev, customer } = useNewOrder();

  const queryClient = useQueryClient();
  const { isLoading: isCreatingOrder, mutate: createOrder } = useMutation(
    (body: OrderRequest) => ordersService.create(body, { user }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["orders"] });
        queryClient.invalidateQueries({ queryKey: ["customers"] });
        next();
      },
    },
  );

  if (!customerId || !orderId) {
    return <div>404 Not found</div>;
  }

  if (!order || !carpets) {
    return null;
  }

  const hasRemainingPrices = order?.orderItems.some((orderItem) => !orderItem.price);

  const dynamicPriceOrderItems = order!.orderItems.filter(isDynamicOrderItem);
  const fixedPriceOrderItems = order!.orderItems.filter(isFixedOrderItem);

  let groupedFixedPriceOrderItemsTypesById: GroupedFixedPriceOrderItemsTypesById = {};
  for (const fixedOrderItem of fixedPriceOrderItems) {
    if (!groupedFixedPriceOrderItemsTypesById[fixedOrderItem.itemId]) {
      groupedFixedPriceOrderItemsTypesById[fixedOrderItem.itemId] = {
        name: fixedPriceMap.get(fixedOrderItem.itemId)!,
        hasFixedPrice: true,
        amount: 1,
      };

      continue;
    }

    groupedFixedPriceOrderItemsTypesById[fixedOrderItem.itemId].amount += 1;
  }

  // function handleCreation(data: FormValues) {
  //   const payload = parseOrderItems(data.orderItems);
  //
  //   createOrder({
  //     customerId: customer!.id,
  //     // debt is not implemented atm
  //     debt: 0,
  //     orderItems: payload,
  //   });
  // }

  const netPrice = calculateNetPrice(order!.orderItems);
  const taxes = netPrice * 0.24;
  const grossPrice = netPrice + taxes;

  return (
    <>
      <Breadcrumb
        items={[
          {
            href: "/customers",
            title: (
              <>
                <span>Πελάτες</span>
              </>
            ),
          },
          {
            title: (
              <>
                <span>Παραγγελίες πελάτη</span>
              </>
            ),
            href: `/customers/${customerId}`,
          },
          {
            title: (
              <>
                <span>Παραγγελία</span>
              </>
            ),
          },
        ]}
      />
      <div
        style={{
          width: "50vw",
          height: "75vh",
          margin: "auto",
          marginTop: "24px",
          background: "#fff",
          padding: "18px",
          display: "flex",
          flexDirection: "column",
          borderRadius: "4px",
        }}
      >
        {isLoading ? (
          <Skeleton />
        ) : (
          <Title level={3}>{`${order?.customer.lastName} ${order?.customer.firstName}`}</Title>
        )}
        {!isLoading &&
          order!.orderItems.map((orderItem, idx) => {
            const item = {
              id: orderItem.id,
              name: orderItem.item.name,
              price: orderItem.price,
              amount: 1,
              width: orderItem.width,
              height: orderItem.height,
              hasFixedPrice: orderItem.item.hasFixedPrice,
            };

            const { price, ...rest } = item;

            return (
              <Row style={{ marginTop: "4px" }} key={idx}>
                {isFixedOrderItem(orderItem) ? (
                  <FixedOrderItem {...orderItem} />
                ) : (
                  <DynamicOrderItem {...orderItem} />
                )}
              </Row>
            );
          })}
        <div style={{ marginTop: "auto" }}>
          <Row style={{ marginTop: "38px" }}>
            <Col offset={19} span={3}>
              {isLoading ? (
                <Skeleton.Input active size="small" />
              ) : (
                <Typography style={{ fontSize: "20px" }}>Καθαρό ποσό</Typography>
              )}
            </Col>
            <Col style={{ textAlign: "right" }} span={2}>
              <Typography.Text style={{ fontSize: "20px", fontVariantNumeric: "tabular-nums" }}>
                {formatPrice(netPrice)}
              </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col offset={19} span={3}>
              {isLoading ? null : (
                <Typography.Text style={{ fontSize: "20px", color: grey[6], fontWeight: 300 }}>
                  Φ.Π.Α.
                </Typography.Text>
              )}
            </Col>
            <Col style={{ textAlign: "right" }} span={2}>
              <Typography.Text
                style={{
                  fontSize: "20px",
                  color: grey[6],
                  fontWeight: 300,
                  fontVariantNumeric: "tabular-nums",
                }}
              >
                {formatPrice(taxes)}
              </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col offset={19} span={3}>
              {isLoading ? (
                <Skeleton.Input active size="small" />
              ) : (
                <Typography.Text strong style={{ fontSize: "20px", fontWeight: 300 }}>
                  Τελικό ποσό
                </Typography.Text>
              )}
            </Col>
            <Col style={{ textAlign: "right" }} span={2}>
              <Typography.Text
                strong
                style={{ fontSize: "20px", fontVariantNumeric: "tabular-nums" }}
              >
                {formatPrice(grossPrice)}
              </Typography.Text>
            </Col>
          </Row>
        </div>
      </div>
      <div
        style={{
          width: "50vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "end",
          marginTop: "24px",
          marginLeft: "auto",
          marginRight: "auto",
          gap: "8px",
        }}
      >
        <Space align="end">
          <Button
            disabled={isLoading}
            type="link"
            onClick={() => {
              navigate(`/customers/${customerId}/orders/${orderId}/edit`);
            }}
          >
            Τροποποίηση διαστάσεων
          </Button>
          <Button
            disabled={hasRemainingPrices || isLoading}
            type="primary"
            loading={isCreatingOrder}
            onClick={() => {
              // handleCreation(orderState);
            }}
          >
            Υποβολή παραγγελίας
          </Button>
        </Space>
        <em style={{ color: red[4], fontSize: "14px" }}>
          {hasRemainingPrices &&
            "Για να υποβάλλεις την παραγγελία, χρειάζεται να δοθούν τετραγωνικά σε όλα τα τεμάχια"}
        </em>
      </div>
    </>
  );
};

const OrderView = () => (
  <NewOrderProvider>
    <OrderView1 />
  </NewOrderProvider>
);

export { OrderView };
