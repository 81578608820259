import { StrictMode as ReactStrictMode } from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "@/AuthProvider";
import ErrorBoundary from "@/ErrorBoundary";
import ErrorPage from "@/ErrorPage";
import * as Sentry from "@sentry/react";

const isDev = process.env.NODE_ENV === "development";

Sentry.init({
  environment: isDev ? "development" : "production",
  dsn: "https://377ef4f74cf1b73744046cb9411d1e0a@o4506432499810304.ingest.sentry.io/4506432521764864",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

let queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    },
  },
});

const container = document.getElementById("root")!;

const root = createRoot(container);

root.render(
  <ReactStrictMode>
    <ErrorBoundary fallback={<ErrorPage />}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <App />
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthProvider>
    </ErrorBoundary>
  </ReactStrictMode>,
);
