import { RequestArgs, AuthorizationService } from "@/declaration";
import { CustomerRequest, CustomerResponse } from "../declaration";
import { authorizedRequest } from "@/utils";

class CustomersService
  implements AuthorizationService<CustomerRequest, any, CustomerResponse, any>
{
  async create(body: CustomerRequest, { user }: RequestArgs): Promise<CustomerResponse> {
    const customer = authorizedRequest<CustomerResponse>("/customers", {
      method: "POST",
      body,
      user,
    });

    return customer;
  }

  async delete(customerId: string, { user }: RequestArgs): Promise<CustomerResponse> {
    const customer = authorizedRequest<CustomerResponse>(`/customers/${customerId}`, {
      method: "DELETE",
      user,
    });

    return customer;
  }

  async update(districtId: string, body: CustomerRequest): Promise<CustomerResponse> {
    let res = await fetch(`/customers/${districtId}`, {
      body: JSON.stringify(body),
    });

    let customer: CustomerResponse = await res.json();

    return customer;
  }

  async find(
    customerId: string | undefined,
    { user }: RequestArgs,
  ): Promise<CustomerResponse | undefined> {
    const customer = await authorizedRequest<CustomerResponse | undefined>(
      `/customers/${customerId}`,
      {
        method: "GET",
        user,
      },
    );

    return customer;
  }

  async findAll({ user }: RequestArgs): Promise<CustomerResponse[]> {
    const customers = await authorizedRequest<CustomerResponse[]>("/customers", {
      method: "GET",
      user,
    });

    return customers;
  }
}

let customersService = new CustomersService();

export { customersService };
