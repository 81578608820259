import { FunctionComponent } from "react";
import OrdersTable from "./OrdersTable";
import { Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { OrderResponse } from "@/orders/declaration";
import { Link } from "react-router-dom";

const { Title } = Typography;

const OrderActions = (order: OrderResponse) => {
  return (
    <>
      <Link to={`/orders/${order.id}`}>Προβολή</Link>
    </>
  );
};
const OrdersView: FunctionComponent<{}> = () => {
  const columns: ColumnsType = [
    {
      title: "Ημερομηνία παραγγελίας",
      key: "orderDate",
      dataIndex: "orderDate",
      render: (date) => {
        return (
          <div>
            {new Intl.DateTimeFormat("el-GR", {
              dateStyle: "long",
              timeZone: "Europe/Athens",
            }).format(new Date(date))}
          </div>
        );
      },
    },
    // {
    //   title: "Κατάσταση παραγγελίας",
    //   key: "state",
    //   render: (order: OrderResponse) => {
    //     if (order.debt === 0) {
    //       return <Tag color="success">Πληρωμένή</Tag>;
    //     }
    //
    //     if (order.debt === order.summary) {
    //       return <Tag color="warning">Μερικώς πληρωμένη</Tag>;
    //     }
    //
    //     return <Tag color="error">Απλήρωτη</Tag>;
    //   },
    // },
    {
      title: "Eνέργειες",
      key: "actions",
      render: OrderActions,
    },
  ];

  return (
    <>
      <Title level={4} style={{ marginBottom: "16px" }}>
        Παραγγελίες
      </Title>
      <OrdersTable />
    </>
  );
};

export default OrdersView;
