import { Button, Layout, Menu, MenuProps, Grid } from "antd";
import React, { FunctionComponent, useLayoutEffect, useState } from "react";
import {
  HomeOutlined,
  UserOutlined,
  FolderOutlined,
  InboxOutlined,
  SettingOutlined,
  LogoutOutlined,
  CalculatorOutlined,
} from "@ant-design/icons";
import { Routes, Route, RouteProps, Link, useLocation } from "react-router-dom";
import "./style.scss";
import Home from "@/Home";
import Customers from "@/customers/CustomersView";
import OrdersView from "@/orders/OrdersView/OrdersView";
import NewOrder from "@/orders/NewOrder/NewOrder";
import Cms from "@/cms/Cms";
import { useAuth } from "@/AuthProvider";
import CustomerView from "@/customers/CustomerView/CustomerView";
import { NewOrderV2 } from "@/orders/NewOrderV2/NewOrderV2";
import { OrdersWithoutDimensions } from "@/orders/OrdersWithoutDimensions/OrdersWithoutDimensions";
import { OrderView } from "@/orders/OrderView/OrderView";
import { EditOrder } from "@/orders/EditOrder/EditOrder";

const { Content, Sider, Header, Footer } = Layout;

const routes: (RouteProps & { key: React.Key })[] = [
  {
    path: "/",
    element: <Home />,
    key: "home",
  },
  {
    path: "customers",
    element: <Customers />,
    key: "customers",
  },
  {
    path: "customers/:customerId/orders?",
    element: <CustomerView />,
    key: "customer-view",
  },
  {
    path: "customers/:customerId/orders/:orderId",
    element: <OrderView />,
    key: "order-view",
  },
  {
    path: "orders",
    element: <OrdersView />,
    key: "orders",
  },
  {
    path: "/customers/:customerId/orders/new",
    element: <NewOrderV2 />,
    key: "orders-new",
  },
  {
    path: "/customers/:customerId/orders/:orderId/edit",
    element: <EditOrder />,
    key: "orders-edit",
  },
  {
    path: "/cms",
    element: <Cms />,
    key: "cms",
  },
  {
    path: "/orders-without-dimensions",
    element: <OrdersWithoutDimensions />,
    key: "orders-without-dimensions",
  },
  {
    path: "*",
    element: <div>Reached no man's land</div>,
    key: "404",
  },
];

const menuItems: Required<MenuProps>["items"] = [
  {
    key: "home",
    label: "Αρχική",
    icon: (
      <Link to="/">
        <HomeOutlined />
      </Link>
    ),
  },
  {
    key: "customers",
    label: "Πελάτες",
    icon: (
      <Link to="/customers">
        <UserOutlined />
      </Link>
    ),
  },
  {
    key: "orders",
    label: "Παραγγελίες",
    icon: (
      <Link to="/orders">
        <FolderOutlined />
      </Link>
    ),
  },
  {
    key: "orders-without-dimensions",
    label: "Καταχώρηση m²",
    icon: (
      <Link to="/orders-without-dimensions">
        <CalculatorOutlined />
      </Link>
    ),
  },
  {
    key: "storage",
    label: "Αποθήκη",
    icon: (
      <Link to="/storage">
        <InboxOutlined />
      </Link>
    ),
  },
  {
    key: "cms",
    label: "Διαχειριστικό",
    icon: (
      <Link to="/cms">
        <SettingOutlined />
      </Link>
    ),
  },
];

const { useBreakpoint } = Grid;

const getSelectedRoute = (route: string) => {
  const [_slash, desiredRoute] = route.split("/");

  const selectedRoute = menuItems.find((item) => item!.key === desiredRoute);
  if (!selectedRoute) {
    return "home";
  }

  return selectedRoute.key as string;
};

const LayoutView: FunctionComponent = () => {
  const screens = useBreakpoint();
  const location = useLocation();
  const selectedRoute = getSelectedRoute(location.pathname);
  const { signOut } = useAuth();
  const [collapsed, setCollapsed] = useState(false);

  useLayoutEffect(() => {
    setCollapsed(!screens.xl ?? false);
  }, [screens.xl]);

  return (
    <Layout>
      <Header style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <Button
          type="primary"
          size="large"
          icon={<LogoutOutlined style={{ rotate: "180deg" }} />}
          onClick={signOut}
        >
          Αποσύνδεση
        </Button>
      </Header>
      <Layout>
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <Menu items={menuItems} defaultSelectedKeys={["home"]} selectedKeys={[selectedRoute]} />
        </Sider>
        <Content style={{ marginTop: 0 }}>
          <div className="view-wrapper">
            <Routes>
              {routes.map((props) => {
                return <Route {...props} />;
              })}
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutView;
