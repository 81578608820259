import { initializeApp, FirebaseError } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  User,
  type UserCredential,
  signInWithEmailAndPassword,
  AuthErrorCodes,
} from "firebase/auth";
import * as React from "react";
import { useEffect, useState } from "react";
import { FirebaseOptions } from "@firebase/app";

interface AuthContext {
  signIn: (email: string, password: string) => Promise<null | undefined>;
  signOut: () => Promise<void>;
  user: User | null;
  userError: string;

  isSigningInUser: boolean;
}

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyDdDARAlIg3v-wfz6MVpJ1r9VWKYBYO_R8",
  authDomain: "carpets-76fc1.firebaseapp.com",
  projectId: "carpets-76fc1",
  storageBucket: "carpets-76fc1.appspot.com",
  messagingSenderId: "33420701490",
  appId: "1:33420701490:web:d80b652e1a184013edb12b",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const AuthContext = React.createContext<AuthContext | undefined>(undefined);
const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
};

const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<User | null>(null);
  const [isSigningInUser, setSigningInUser] = useState(true);
  const [userError, setUserError] = useState("");

  const signIn = async (email: string, password: string) => {
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);

      if (!user) {
        return null;
      }
    } catch (e) {
      if (e instanceof FirebaseError) {
        setUserError(e.message);
      }
    }
  };

  const signOutUser = async () => {
    try {
      await signOut(auth);
      setUser(() => null);
    } catch (e) {
      console.log(e);
    } finally {
      setSigningInUser(() => false);
    }
  };

  const value: AuthContext = {
    signIn,
    user,
    userError,
    isSigningInUser,
    signOut: signOutUser,
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (user) => {
        if (!user) {
          setSigningInUser(() => false);
          return;
        }

        try {
          setUser(() => user);
          setUserError(() => "");
        } catch (e) {
          setUser(() => user);
          // For now as any
          setUserError(() => e as any);
        } finally {
          setSigningInUser(() => false);
        }

        return () => {
          unsubscribe();
        };
      },
      (err) => {},
    );
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider, useAuth };
