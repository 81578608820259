import {
  OrderItemResponse,
  DynamicOrderItemResponse,
  FixedOrderItemResponse,
} from "@/orderItems/declaration";

function isDynamicOrderItem(orderItem: OrderItemResponse): orderItem is DynamicOrderItemResponse {
  return (
    (orderItem.width != null && orderItem.height != null) ||
    (orderItem.width == null && orderItem.width == null && orderItem.price == null)
  );
}

function isFixedOrderItem(orderItem: OrderItemResponse): orderItem is FixedOrderItemResponse {
  return orderItem.width == null && orderItem.height == null && orderItem.price != null;
}

export { isDynamicOrderItem, isFixedOrderItem };
