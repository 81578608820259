import { CustomerResponse } from "@/customers/declaration";
import { FunctionComponent, useState } from "react";
import { Button, Input, Modal } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { customersService } from "@/customers/service";
import { NavLink } from "react-router-dom";

const DELETE_WORD = "ΔΙΑΓΡΑΦΗ";
const isValidName = ({ input }: { firstName: string; lastName: string; input: string }) => {
  return input === DELETE_WORD;
};

interface CustomerActionsProps {
  customer: CustomerResponse;
  openNotification: () => void;
}

const CustomerActions: FunctionComponent<CustomerActionsProps> = ({ customer, openNotification }) => {
  // let queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [input, setInput] = useState("");

  // let { isLoading: isDeletingCustomer, mutate: deleteCustomer } = useMutation(customersService.delete, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({ queryKey: ["customers"] });
  //     setIsModalOpen(() => false);
  //     openNotification();
  //   },
  // });

  const { id, lastName, firstName } = customer;
  // const onDeletion = () => {
  //   deleteCustomer(id);
  // };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <NavLink to={`/customers/${id}/orders/new`}>Δημιουργία παραγγελίας</NavLink>
      {/*<Button type="link" onClick={() => setIsModalOpen(() => true)}>*/}
      {/*  Διαγραφή πελάτη*/}
      {/*</Button>*/}
      {/*<Modal*/}
      {/*  width="50vw"*/}
      {/*  title="Διαγραφή πελάτη"*/}
      {/*  okText="Διαγραφή"*/}
      {/*  open={isModalOpen}*/}
      {/*  onOk={onDeletion}*/}
      {/*  okButtonProps={{*/}
      {/*    type: "primary",*/}
      {/*    danger: true,*/}
      {/*    loading: isDeletingCustomer,*/}
      {/*    disabled: !isValidName({ input, firstName, lastName }),*/}
      {/*    onClick: onDeletion,*/}
      {/*  }}*/}
      {/*  onCancel={() => {*/}
      {/*    setIsModalOpen(() => false);*/}
      {/*    setInput(() => "");*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div>*/}
      {/*    Πρόκειται να διαγραφεί ο πελάτης <strong>{`${lastName} ${firstName}`} </strong> καθώς και{" "}*/}
      {/*    <strong> όλες του οι παραγγελίες</strong>.*/}
      {/*  </div>*/}
      {/*  <br />*/}
      {/*  <strong>Αυτή η ενέργεια είναι μη αναστρέψιμη.</strong>*/}
      {/*  <div*/}
      {/*    style={{*/}
      {/*      display: "flex",*/}
      {/*      alignItems: "center",*/}
      {/*      marginTop: "48px",*/}
      {/*      marginLeft: "auto",*/}
      {/*      marginRight: "auto",*/}
      {/*      columnGap: "20px",*/}
      {/*      paddingTop: "16px",*/}
      {/*      paddingBottom: "16px",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <label>{`Πληκτρολογήστε "${DELETE_WORD}" για επιβεβαίωση: `}</label>*/}
      {/*    <Input style={{ width: "180px" }} value={input} onChange={(e) => setInput(e.target.value)} type="text" />*/}
      {/*  </div>*/}
      {/*</Modal>*/}
    </div>
  );
};

export default CustomerActions;
