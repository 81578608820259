import "./App.css";
import LayoutView from "./LayoutView";
import { useAuth } from "@/AuthProvider";
import { Button, Form, Input, Typography } from "antd";

const { Text } = Typography;

function App(): JSX.Element {
  const { signIn, user, userError, isSigningInUser } = useAuth();

  if (isSigningInUser) {
    return <div>loading</div>;
  }

  async function onClick({ email, password }: { email: string; password: string }) {
    await signIn(email, password);
  }

  if (!user) {
    return (
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 12 }}
        style={{
          maxWidth: 600,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
        }}
        initialValues={{ remember: true }}
        onFinish={onClick}
        autoComplete="off"
      >
        <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please input your username!" }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Σύνδεση
          </Button>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
          <Text type="danger">{userError ? <div>Invalid credentials</div> : null}</Text>
        </Form.Item>
      </Form>
    );
  }

  return <LayoutView />;
}

export default App;
