import { formatPrice } from "@/orders/utils";
import { Col } from "antd";
import { OrderItemResponse } from "@/orderItems/declaration";
import { FixedPriceOrderItemsGroup } from "@/orders/OrderView/OrderView";

const FixedOrderItem = (props: OrderItemResponse) => {
  return (
    <>
      <Col span={1}>1</Col>
      {/* TODO plural: Low importance */}
      <Col span={1}>{props.item.name}</Col>
      <Col style={{ textAlign: "right" }} span={22}>
        {formatPrice(props.price!)}
      </Col>
    </>
  );
};

export { FixedOrderItem };
