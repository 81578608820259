import { Button, notification, Space } from "antd";
import { FunctionComponent, useState } from "react";
import CustomerCreation from "@/customers/CustomerCreation/CustomerCreation";
import CustomersTable from "./CustomersTable";
import { PlusOutlined, CheckCircleTwoTone } from "@ant-design/icons";

interface CustomersProps {
  fullName: string;
}

const key = "customerCreationNotification";

const Customers: FunctionComponent<{}> = () => {
  const [isOpen, setIsOpen] = useState(false);

  function onButtonClick() {
    setIsOpen(true);
  }

  function onModalCancel() {
    setIsOpen(false);
  }

  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api.open({
      key,
      message: "Δημιουργία πελάτη",
      description: "Ο πελάτης σας δημιουργήθηκε επιτυχώς.",
      placement: "bottomRight",
      icon: <CheckCircleTwoTone />,
    });
  };

  return (
    <>
      {contextHolder}
      <Button
        onClick={onButtonClick}
        icon={<PlusOutlined />}
        type="primary"
        size="large"
        style={{ marginBottom: "24px" }}
      >
        Δημιουργία πελάτη
      </Button>
      {isOpen && (
        <CustomerCreation
          onCancel={onModalCancel}
          onCustomerCreation={() => {
            setIsOpen(false);

            setTimeout(() => {
              openNotification();
            }, 500);
          }}
          open={isOpen}
        />
      )}
      <CustomersTable />
    </>
  );
};

export default Customers;
