import { CustomerResponse, OrderOfCustomerResponse } from "@/customers/declaration";
import React, { FunctionComponent, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { customersService } from "@/customers/service";
import { useAuth } from "@/AuthProvider";
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, Empty, Space, Table, Tag, Typography } from "antd";
import { HomeOutlined, UnorderedListOutlined, UserOutlined } from "@ant-design/icons";
import { OrderResponse } from "@/orders/declaration";
import { ColumnsType } from "antd/lib/table";

const { Title } = Typography;

const OrderActions = (order: OrderOfCustomerResponse) => {
  return <div>{<Link to={`/customers/${order.customerId}/orders/${order.id}`}>Προβολή</Link>}</div>;
};

const columns: ColumnsType<OrderOfCustomerResponse> = [
  {
    title: "Ημερομηνία παραγγελίας",
    key: "orderDate",
    dataIndex: "orderDate",
    render: (date) => {
      return (
        <div>
          {new Intl.DateTimeFormat("el-GR", {
            dateStyle: "long",
            timeZone: "Europe/Athens",
          }).format(new Date(date))}
        </div>
      );
    },
  },
  // {
  //   title: "Κατάσταση παραγγελίας",
  //   key: "state",
  //   render: (order: OrderResponse) => {
  //     if (order.debt === 0) {
  //       return <Tag color="success">Πληρωμένή</Tag>;
  //     }
  //
  //     if (order.debt === order.summary) {
  //       return <Tag color="warning">Μερικώς πληρωμένη</Tag>;
  //     }
  //
  //     return <Tag color="error">Απλήρωτη</Tag>;
  //   },
  // },
  {
    title: "Eνέργειες",
    key: "actions",
    render: OrderActions,
  },
];

function composeTableData(
  data: OrderOfCustomerResponse[],
): (OrderOfCustomerResponse & { key: string })[] | undefined {
  if (!data) {
    return;
  }

  return data.map((datum) => ({
    ...datum,
    key: datum.id,
  }));
}

interface CustomerViewProps {}

const CustomersView: FunctionComponent<CustomerViewProps> = () => {
  const { user } = useAuth();
  const { customerId } = useParams();

  const {
    data: customer,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["customers", customerId],
    queryFn: () => customersService.find(customerId, { user }),
  });

  if (isLoading) {
    return <div>loading</div>;
  }

  if (!customer) {
    return <Empty description="Δε βρέθηκε πελάτης" />;
  }

  return (
    <>
      <Breadcrumb
        items={[
          {
            href: "/customers",
            title: (
              <>
                <span>Πελάτες</span>
              </>
            ),
          },
          {
            title: (
              <>
                <span>Παραγγελίες πελάτη</span>
              </>
            ),
          },
        ]}
      />
      <Typography.Title level={3}>{customer?.lastName + " " + customer.firstName}</Typography.Title>
      <div style={{ marginBottom: "32px", display: "block", marginTop: "-12px" }}>
        {customer.address + ", " + customer.city.name}
      </div>
      <Table<OrderOfCustomerResponse>
        style={{
          width: "100%",
        }}
        loading={isLoading}
        size={"small"}
        dataSource={composeTableData(customer.orders)}
        columns={columns}
        rowKey={"id"}
      />
    </>
  );
};

export default CustomersView;
