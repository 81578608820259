import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Space,
  Table,
  Typography,
} from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { carpetsService } from "@/carpets/service";
import { useAuth } from "@/AuthProvider";
import { ColumnsType } from "antd/lib/table";
import { formatPrice } from "@/orders/utils";
import { CarpetRequest, CarpetResponse } from "@/carpets/declaration";
import { CheckCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";

const key = "carpetCreationNotification";

const columns: ColumnsType<CarpetResponse> = [
  {
    title: "Όνομα",
    key: "name",
    dataIndex: ["name"],
  },
  {
    title: "Τιμή",
    key: "price",
    align: "right",
    render: (carpet) => {
      const formattedPrice = formatPrice(carpet.price);

      return (
        <div>
          {formattedPrice}
          {carpet.hasFixedPrice ? "" : " / τμ."}
        </div>
      );
    },
  },
];

type CarpetCreationFormValues = {
  name: string;
  price: number;
  hasPricePerSquareMeter: boolean;
};

function composeCarpetRequestBody(values: CarpetCreationFormValues): CarpetRequest {
  const { name, price, hasPricePerSquareMeter } = values;

  return {
    name,
    price,
    hasFixedPrice: !hasPricePerSquareMeter,
  };
}

const { Title } = Typography;
const Cms = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const { data: carpets, isLoading: isLoadingCarpets } = useQuery({
    queryKey: ["carpets"],
    queryFn: () => carpetsService.findAll({ user }),
  });

  const { isLoading: isLoadingCreateCarpetType, mutate: createCarpetType } = useMutation(
    (body: CarpetRequest) => carpetsService.create(body, { user }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["carpets"] });
        closeModal();
        openNotification();
      },
    },
  );

  const [api, contextHolder] = notification.useNotification();
  const [isOpen, setIsOpen] = useState(false);
  const [form] = Form.useForm<CarpetCreationFormValues>();
  const openNotification = () => {
    api.open({
      key,
      message: "Δημιουργία τύπου",
      description: "Ο τύπος χαλιού δημιουργήθηκε επιτυχώς.",
      placement: "bottomRight",
      icon: <CheckCircleTwoTone />,
    });
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    form.resetFields();
  }

  function handleSubmit(values: CarpetCreationFormValues) {
    const requestBody = composeCarpetRequestBody(values);
    createCarpetType(requestBody);
  }

  return (
    <>
      {contextHolder}
      <>
        <Title level={4}>Τύποι χαλιών</Title>
        <Space size={"large"} direction={"vertical"} style={{ display: "flex" }}>
          <Button size={"large"} type="primary" icon={<PlusOutlined />} onClick={openModal}>
            Δημιουργία τύπου
          </Button>
          {isOpen && (
            <Modal
              open={isOpen}
              title="Δημιουργία τύπου"
              onOk={form.submit}
              width={"700px"}
              confirmLoading={isLoadingCreateCarpetType}
              okText="Δημιουργία"
              cancelText="Ακύρωση"
              onCancel={closeModal}
            >
              <Form<CarpetCreationFormValues>
                onFinish={handleSubmit}
                form={form}
                initialValues={{
                  hasPricePerSquareMeter: true,
                }}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 12 }}
              >
                <Form.Item
                  label="Όνομα"
                  name="name"
                  rules={[{ required: true, message: "Το όνομα είναι υποχρεωτικό πεδίο" }]}
                >
                  <Input placeholder="π.χ. Μοκέτα" />
                </Form.Item>
                <Form.Item
                  label="Τιμή"
                  name="price"
                  rules={[
                    { required: true, message: "Η τιμή είναι υποχρεωτικό πεδίο" },
                    {
                      validator: async (_, value) => {
                        if (value == 0) {
                          throw new RangeError("H διάσταση πρέπει να είναι μεγαλύτερη από 0");
                        }
                      },
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    type="number"
                    value={0}
                    addonAfter={"€"}
                    parser={(value) => Number(value!.replace(/[A-Z]/, ""))}
                    precision={2}
                    decimalSeparator={"."}
                    controls={false}
                  />
                </Form.Item>
                <Form.Item
                  valuePropName="checked"
                  label="Η τιμή ορίζεται ανά τμ."
                  name="hasPricePerSquareMeter"
                >
                  <Checkbox />
                </Form.Item>
              </Form>
            </Modal>
          )}

          <Table rowKey="id" loading={isLoadingCarpets} columns={columns} dataSource={carpets} />
        </Space>
      </>
    </>
  );
};

export default Cms;
