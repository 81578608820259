import { FunctionComponent } from "react";
import { Modal, Button, ModalProps, Form, Input, Select } from "antd";
import MinusCircleOutlined from "@ant-design/icons/lib/icons/MinusCircleOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import "./styles.scss";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { customersService } from "@/customers/service";
import { validateGreekPhoneNumber } from "@/utils";
import { citiesService } from "@/cities/service";
import { CityResponse } from "@/cities/declaration";
import { SelectOption } from "@/declaration";
import { useAuth } from "@/AuthProvider";

interface CustomerCreationProps extends ModalProps {
  onCustomerCreation: () => void;
}

let { Option } = Select;

type FormValues = {
  firstName: string;
  lastName: string;
  cityId: string;
  address: string;
  phoneNumbers: string[];
};

let CustomerCreation: FunctionComponent<CustomerCreationProps> = ({ onCustomerCreation, ...rest }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm<FormValues>();
  const { user } = useAuth();

  const { isLoading: isLoadingCustomerCreation, mutate: mutateCustomerCreation } = useMutation(
    (formValues: FormValues) => customersService.create(formValues, { user }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["customers"] });
        onCustomerCreation();
      },
    },
  );

  const { data: cities, isLoading: isLoadingCities } = useQuery({
    queryKey: ["cities"],
    queryFn: () => citiesService.findAll({ user }),
  });

  const citiesOptions: (SelectOption & CityResponse)[] | undefined = cities?.map((city) => {
    return {
      ...city,
      label: city.name,
      value: city.id,
    };
  });

  function handleSubmit(values: FormValues) {
    mutateCustomerCreation(values);
  }

  return (
    <Modal
      title="Δημιουργία πελάτη"
      onOk={form.submit}
      width={"700px"}
      confirmLoading={isLoadingCustomerCreation}
      okText="Αποθήκευση"
      cancelText="Ακύρωση"
      {...rest}
    >
      <Form
        onFinish={handleSubmit}
        form={form}
        initialValues={{ phoneNumbers: [""] }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        requiredMark="optional"
      >
        <Form.Item
          label="Όνομα"
          name="firstName"
          rules={[{ required: true, message: "Το όνομα είναι υποχρεωτικό πεδίο" }]}
        >
          <Input placeholder="π.χ. Αναστάσιος" />
        </Form.Item>
        <Form.Item
          label="Επίθετο"
          name="lastName"
          rules={[{ required: true, message: "Το επίθετο είναι υποχρεωτικό πεδίο" }]}
        >
          <Input placeholder="π.χ. Θεοδωρακόπουλος" />
        </Form.Item>
        <Form.Item
          hasFeedback
          validateStatus={isLoadingCities ? "validating" : ""}
          name="cityId"
          label="Πόλη"
          rules={[{ required: true, message: "Η πόλη είναι υποχρεωτικό πεδίο" }]}
        >
          <Select placeholder="Επίλεξε πόλη">
            {citiesOptions?.map((city) => (
              <Option key={city.id} value={city.value}>
                {city.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Διεύθυνση"
          name="address"
          rules={[{ required: true, message: "Η διεύθυνση είναι υποχρεωτικό πεδίο" }]}
        >
          <Input placeholder="π.χ. Πηνελόπης Δέλτα 12" />
        </Form.Item>
        <Form.List
          name="phoneNumbers"
          rules={[
            {
              validator: async (_, phoneNumbers) => {
                if (!phoneNumbers || !phoneNumbers.length) {
                  throw new Error("Καταχωρήστε τουλάχιστον 1 τηλέφωνο");
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => {
                console.log(field, "to name");
                return (
                  <Form.Item label="Αριθμός τηλεφώνου" required key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={["onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          // message: "Πρόσθεσε αριθμό τηλεφώνου",
                          validator: async (_, phoneNumber) => {
                            if (phoneNumber == undefined) {
                              throw new Error("Συμπλήρωσε αριθμό τηλεφώνου");
                            }

                            if (!validateGreekPhoneNumber(phoneNumber)) {
                              throw new Error("Διόρθωσε τον αριθμό τηλεφώνου");
                            }
                          },
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="2721029999" style={{ width: "250px" }} />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
                    ) : null}
                  </Form.Item>
                );
              })}

              <Form.Item wrapperCol={{ offset: 6 }}>
                <Button style={{ width: "250px" }} block type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                  Προσθήκη τηλεφώνου
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default CustomerCreation;
