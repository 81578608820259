import { RequestArgs, AuthorizationService } from "@/declaration";
import { OrderItemResponse, OrderItemPayloadWithDimensions } from "../declaration";
import { ApiError, authorizedRequest } from "@/utils";

class OrderItemsService implements AuthorizationService<any, any, OrderItemResponse, any> {
  async create(body: any, { user, params }: RequestArgs): Promise<OrderItemResponse> {
    throw new ApiError({ message: "Not implemented on client side", statusCode: 501 });
  }

  async delete(orderId: string): Promise<OrderItemResponse> {
    throw new ApiError({ message: "Not implemented on client side", statusCode: 501 });
  }

  async update(orderId: string, body: any): Promise<OrderItemResponse> {
    throw new ApiError({ message: "Not implemented on client side", statusCode: 501 });
  }

  async updateMany(
    body: OrderItemPayloadWithDimensions[],
    { user }: RequestArgs,
  ): Promise<OrderItemResponse[]> {
    const orderItems = await authorizedRequest<OrderItemResponse[]>(`/order-items`, {
      method: "PUT",
      user,
      body: {
        orderItems: body,
      },
    });

    return orderItems;
  }

  async find<ParamsType>(
    orderId: string | undefined,
    { user, params }: RequestArgs<ParamsType>,
  ): Promise<OrderItemResponse | undefined> {
    if (orderId === undefined) {
      return undefined;
    }

    const order = await authorizedRequest<OrderItemResponse>(`/order-items/${orderId}`, {
      method: "GET",
      user,
    });

    return order;
  }

  async findAll<ParamsType>({
    user,
    params,
  }: RequestArgs<ParamsType>): Promise<OrderItemResponse[]> {
    const orderItems = await authorizedRequest<OrderItemResponse[]>("/order-items", {
      method: "GET",
      user,
    });

    return orderItems;
  }
}

const orderItemsService = new OrderItemsService();

export { orderItemsService };
