import { RequestArgs, AuthorizationService } from "@/declaration";
import { CityRequest, CityResponse } from "../declaration";
import { ApiError, authorizedRequest } from "@/utils";

class CitiesService implements AuthorizationService<CityRequest, any, CityResponse, any> {
  async create(body: CityRequest): Promise<CityResponse> {
    let res = await fetch(`/cities`, {
      body: JSON.stringify(body),
    });

    let city: CityResponse = await res.json();

    return city;
  }

  async delete(cityId: string): Promise<CityResponse> {
    let res = await fetch(`/cities/${cityId}`);

    let city: CityResponse = await res.json();

    return city;
  }

  async update(cityId: string, body: CityRequest): Promise<CityResponse> {
    let res = await fetch(`/cities/${cityId}`, {
      body: JSON.stringify(body),
    });

    let customers: CityResponse = await res.json();

    return customers;
  }

  async find(cityId: string | undefined, { user }: RequestArgs): Promise<CityResponse | undefined> {
    if (cityId === undefined) {
      return;
    }

    let res = await fetch(`/cities/${cityId}`, {
      body: cityId,
    });

    if (!res.ok) {
      const resp = await res.json();
      throw new ApiError({ message: resp.message, statusCode: resp.statusCode });
    }

    let customer: CityResponse = await res.json();

    return customer;
  }

  async findAll({ user }: RequestArgs): Promise<CityResponse[] | undefined> {
    const cities = await authorizedRequest<CityResponse[] | undefined>(`/cities`, {
      method: "GET",
      user,
    });

    return cities;
  }
}

let citiesService = new CitiesService();

export { citiesService };
