import { Button, Form, InputNumber, Result, Select } from "antd";
import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { CarpetResponse } from "@/carpets/declaration";
import { NewOrderProvider, useNewOrder } from "@/orders/NewOrder/NewOrderContext";
import { Typography } from "antd";
import { ApiError } from "@/utils";
import { OrderItemPayload, OrderItemPayloadWithoutDimensions } from "@/orderItems/declaration";
import { Link } from "react-router-dom";
import { OrderItemOfOrderPayloadWithoutDimensions } from "@/orders/declaration";

const { Option } = Select;

const { Title } = Typography;

interface OrderItemPayloadWithoutDimensionsWithQuantity extends OrderItemPayloadWithoutDimensions {
  quantity: number;
}

const NewOrder = () => {
  const [form] = Form.useForm();
  const {
    carpets,
    orderState,
    setOrderState,
    customer,
    customerError,
    isLoadingCustomer,
    isLoadingCarpets,
    createOrder,
    isCreatingOrder,
    isCreateOrderSuccessful,
  } = useNewOrder();

  function handleChange(orderItemId: string, itemIndex: number, carpets: CarpetResponse[]) {
    const { orderItems: previousOrderItems } = form.getFieldsValue(true);

    const selectedCarpet = carpets.find((carpet) => carpet.id === orderItemId)!;

    let newFormValues = [
      ...previousOrderItems.slice(0, itemIndex),
      {
        ...previousOrderItems[itemIndex],
        hasFixedPrice: selectedCarpet.hasFixedPrice,
        name: selectedCarpet.name,
        width: null,
        height: null,
      },
      ...previousOrderItems.slice(itemIndex + 1),
    ];

    form.setFieldsValue({
      orderItems: newFormValues,
    });
  }

  function handleSubmit({
    orderItems,
  }: {
    orderItems: OrderItemPayloadWithoutDimensionsWithQuantity[];
  }) {
    const newOrderItems = orderItems.reduce<OrderItemPayload[]>((arr, orderItem) => {
      const { quantity } = orderItem;

      const newOrderItem: OrderItemOfOrderPayloadWithoutDimensions = {
        itemId: orderItem.itemId,
        width: null,
        height: null,
      };

      return [...arr, ...Array(quantity).fill(newOrderItem)];
    }, []);

    createOrder({
      body: { customerId: customer!.id, orderItems: newOrderItems, debt: 0 },
    });
  }

  const isLoading = isLoadingCustomer || isLoadingCarpets;

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (customerError instanceof ApiError) {
    if (customerError.statusCode == 404) {
      return <div>Customer not found</div>;
    }
  }

  console.log(isCreateOrderSuccessful, ":eiani?");
  if (isCreateOrderSuccessful) {
    return (
      <Result
        status="success"
        title="Η παραγγελία δημιουργήθηκε επιτυχώς."
        extra={[
          <Link key="orders" to="/orders">
            Μετάβαση στη σελίδα παραγγελιών
          </Link>,
        ]}
      />
    );
  }

  return (
    <div>
      <Title level={3}>{`${customer!.lastName} ${customer!.firstName}`}</Title>
      <Form
        onFinish={handleSubmit}
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        requiredMark="optional"
        /*
         * Initial values will pre-add an empty order item
         * on initial page visit
         * */
        initialValues={orderState}
      >
        <Form.List name="orderItems" rules={[]}>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => {
                return (
                  <div style={{ marginTop: "3rem" }} key={field.key}>
                    <Form.Item label="Τύπος χαλιού" key={`id-${field.key}`} required>
                      <Form.Item
                        {...field}
                        noStyle
                        name={[field.name, "itemId"]}
                        rules={[
                          {
                            required: true,
                            message: "Ο τύπος χαλιού είναι υποχρεωτικό πεδίο",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "40%" }}
                          onChange={(value) => handleChange(value, index, carpets!)}
                          placeholder="Επίλεξε τύπο χαλιού"
                        >
                          {carpets?.map((carpet) => (
                            <Option key={carpet.id} value={carpet.id}>
                              {carpet.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                    <Form.Item label="Τεμάχια" required>
                      <Form.Item
                        {...field}
                        noStyle
                        name={[field.name, "quantity"]}
                        rules={[
                          {
                            required: true,
                            message: "H ποσότητα είναι υποχρεωτικό πεδίο",
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "40%" }} />
                      </Form.Item>
                    </Form.Item>
                  </div>
                );
              })}
              <Form.Item wrapperCol={{ offset: 6 }}>
                <Button
                  style={{ width: "250px", margin: "16px" }}
                  block
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Προσθήκη
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Button type="primary" htmlType="submit" loading={isCreatingOrder}>
          Δημιουργία παραγγελίας
        </Button>
      </Form>
    </div>
  );
};

const NewOrderV2 = () => {
  return (
    <NewOrderProvider>
      <NewOrder />
    </NewOrderProvider>
  );
};

export { NewOrderV2 };
